module.exports = `
_site {
  favicon {
    responsiveImage {
      src
    }
  }
  globalSeo {
    siteName
    titleSuffix
  }
}`;
